#sigma-intro {
    .sigma-intro {
        &-img {
            img {
                border-radius: 10px;
                margin-bottom: 1.5em;
                width: 100%;
            }
            &-mobile {
                width: 100%;
                height: 211px;
                object-fit: cover;
                object-position: right;
                margin-bottom: 34px;
            }
        }
    }
    .title {
        text-transform: uppercase;
        color: $color-dark-blue;
    }
    a {
        &.btn {
            margin-top: 20px;
            svg {
                width: 15px;
                height: 13px;
            }

        }
    }
    h2.title {
        &::before {
            content: "";
        }
    }
    .sigma-intro-title {
        margin-bottom: 25px !important;
        // width: 40%;
        margin: 0 auto;
        // @include media-breakpoint-mobile {
        //     width: 100%;
        // }
    }
}

#sigma-gamme {
    background: linear-gradient(90deg, #F6F4F3 -30.92%, rgba(255, 255, 255, 0) 112.63%);
    border-radius: 10px;
    .h3 {
        color: $color-button;
    }
    svg {
        transform: rotate(180deg);
        width: 9px;
        height: 15px;
    }
    a {
        font-weight: 500;
        color: $color-dark-blue;
    }
    .content {
        padding-left: 45px;
    }
    @include media-breakpoint-mobile {
        margin-bottom: 25px;
        .content {
            padding: 24px;
        }
    }
}

#sigma-promo {
    background: linear-gradient(90deg, #FA4026 0%, #FA4026 100%);
    border-radius: 10px;
    div {
        text-align: center;
        p {
            color: #ffffff;
            font-weight: 800;
        }
        a {
            color: #ffffff;
            &:hover {
                color: #ffffff !important;
            }
            svg {
                width: 15px;
                height: 13px;
                fill: #ffffff;
            }
        }
    }
    @include media-breakpoint-mobile {
        // background-image: url(../img/bkgd-promos-mobile.png);
        // background-color: linear-gradient(90deg, #FA4026 0%, #FA4026 100%);
        background: url(../img/bkgd-promos-mobile.png) no-repeat, linear-gradient(90deg, #FA4026 0%, #FA4026 100%);
        background-size: contain;
        height: 300px;
        position: relative;
        div {
            text-align: left;
            position: absolute;
            left: 2em;
            bottom: 2.5em;
        }
    }
}
.slick-arrow {
    cursor: pointer;
    svg {
        width: 12px;
        height: 24px;
    }
}
.slick-prev {
    left: -15px !important;
}
.slick-next {
    right: -15px !important;
}
.home-sigma {
    margin-bottom: 25px;
    .home-intro {
        img {
            width: 100%;
        }
    }
}
#index {
    .h2,h2 {
        color: $color-dark-blue;
        display: inline;
        position: relative;
        &::before {
            content: url(../img/picto-title.svg); 
            position: absolute;
            z-index: -1;
            left: -0.5em;
        }
    }
    .h1 {
        color: $color-dark-blue;
    } 
}

#block-faq {
    width: 80%;
    margin: 0 auto;
    @include media-breakpoint-mobile {
        width: 100%;
    }
    .question {
        padding: 30px 0;
        border-bottom: 1px solid $color-typo;
        .block-title {
            font-size: 17px;
            font-weight: 700;
            line-height: 24px;
            color: $color-dark-blue;
        }
        svg {
            width: 19px;
            height: 25px;
        }
        .qst-block[aria-expanded="true"]{
            svg {
                transform: rotate(-90deg);
            }
        }
        .show {
            padding-top: 30px;
        }
    }
}

.sigma-product-slider {
    @include media-breakpoint-mobile {
        display: flex;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        .product-miniature {
            max-width: 90%;
            .product__card-desc {
                height: initial;
            }
        }
    }
}

.top-ventes-sigma {
    @include media-breakpoint-mobile {
        padding-left: 0;
        padding-right: 0;
    }
}

.block_newsletter {
    #gdpr_consent {
        .custom-control{
            &-label {
                color: #ffffff;
                &::before {
                    border: 1.5px solid #ffffff;
                }
            }
        }
    }
    input[name="email"] {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .btn-primary {
        &:disabled {
            border-color: #ffffff;
            color: #ffffff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            opacity: 1;
        }
    }
}

.color-title {
    color: $color-title;
}

.categories-sigma {
    p {
        margin: 30px 0;
    }
}