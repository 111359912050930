.l-footer {
  color: #ffffff;

  .cms-page-link,
  .account-list a{
    color: #ffffff;
    &:hover{
      color: theme-color('primary');

    }
  }
  //Modification
  a {
    color: #ffffff;
    &:hover {
      color: $color-button !important;
    }
  }
}
.footer-after {
  background: #ffffff;
  padding: 12px 0;
}
.footer-link {
  border-top: 1px solid #ffffff;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  color: #ffffff;
  a {
    color: #ffffff;
    padding: 0 0.5em;
  }
  @include media-breakpoint-mobile {
    margin-top: 34px;
    div {
      text-align: center;
      p {
        margin-top: 22px;
      }
    }
  }
}
.footer-middle {
  border-top: 1px solid #ffffff;
  padding-top: 27px;
  margin-top: 59px;
  margin: 59px 0 55px 0;
  @include media-breakpoint-mobile {
    margin-top: 48px;
  }
  &-info-sigma {
    div {
      svg {
        width: 21px;
        height: 21px;
      }
      a {
        font-weight: 800;
      }
      p {
        font-size: 13px;
      }
    }
    @include media-breakpoint-mobile {
      text-align: center;
      img {
        margin-bottom: 30px;
      }
    }
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
  //Modification
  background: $color-dark-blue;
  padding-top: 66px;

}

.footer__title{
  color:#ffffff;
  &:visited,&:hover{
    color: #ffffff;
  }
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: $spacer/2;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}

#block-newsletter-label {
  .h2 {
    color: #ffffff;
  }
  @include media-breakpoint-mobile {
    text-align: center;
  }
}

.social-block-sigma {
  @include media-breakpoint-mobile {
    margin: 0 auto;
    padding-top: 30px;
  }
}

.footer-info-sigma {
  margin-top: 26px;
}