h1, .h1 {
    font-family: $font;
    font-size: 50px;
    line-height: 55px;
    font-weight: 900;
    text-transform: initial;
    @include media-breakpoint-mobile {
        font-size: 40px;
        line-height: 50px;
    }
}

h2, .h2 {
    font-family: $font;
    font-size: 30px;
    line-height: 43px;
    font-weight: 800;
    @include media-breakpoint-mobile {
        font-size: 30px;
        line-height: 40px;
    }
}

h3, .h3 {
    font-family: $font;
    font-size: 25px;
    line-height: 31px;
    font-weight: 700;
}

.title {
    font-family: $font;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    @include media-breakpoint-mobile {
        font-size: 17px;
        line-height: 24px;
    }
}

.legend {
    font-family: $font;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
}