#product {
    .product-price {
        height: inherit;
    }
    .product-variants {
        &-block{
            border-top: 1px solid $color-light-grey;
            border-bottom: 1px solid $color-light-grey;
            padding: 20px 0;
            margin-bottom: 20px;
        }
        .form-group {
            .custom-control {
                padding-left: 0;
                &-label {
                    min-width: 63px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    padding: 5px 15px;
                    color: $color-typo;
                    font-weight: 700;
                    font-size: 15px;
                    &::before{
                        width: 100%;
                        height: 100%;
                        top: unset;
                        left: unset;
                        z-index: -1;
                    }
                    &::after{
                        display: none;
                    }
                }
                &-input {
                    &:checked~.custom-control-label{ 
                        background: rgba(118, 184, 46, 0.2); 
                        border: 2px solid $color-button;
                    }
                }
                &-input~.custom-control-label {
                    border: 1.5px solid $color-light-grey;
                    border-radius: 5px;
                }
                &.custom-radio {
                    .custom-control-label{
                        &::before {
                            border-radius: initial;
                            border: none;
                        }
                    }
                }
            }
        }
        .product-variants-item {
            .label {
                font-weight: 500;
                span {
                    font-weight: 700;
                }
            }
        }
    }
    .info-attributes {
        color: $color-button;
        span {
            .delimiter-1 {
                display: none;
            }
        } 
    }
    .product-section {
        &-title {
            border-bottom: 5px solid $color-light-grey;
            margin-bottom: 19px;
        }
    }
    .product-description-content {
        column-count: 2;
        ul {
            list-style: initial;
            padding-left: 1em;
        }
        @include media-breakpoint-mobile {
            column-count: 1;

        }
    }
    .section {
        margin-top: 60px;
        .crop-product {
            width: 108px;
            height: 108px;
        }
    }
    .table-product-discounts-block {
        border: 1.5px solid $color-light-grey;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 5px;
        .table-product-discounts {
            border-spacing: 5px;
            border-collapse: separate;
            table-layout: auto;
            width: 100%;
            td {
                width: 122px;
                background: $color-lighter-grey;
                text-align: center;
                font-size: 12px;
                font-weight: 500;
                line-height: 25px;
                color: $color-typo;
                border-radius: 3px;
                &.qty {
                    background: $color-light-grey;
                    text-align: left;
                    font-weight: 500;
                    padding-left: 12px;
                }
                &.unit-price {
                    background: $color-typo;
                    color: #ffffff;
                    text-align: left;
                    font-weight: 500;
                    padding-left: 12px;
                }
            }
        }
        span {
            font-size: 12px;
            line-height: 25px;
            padding-left: 5px;
            color: $color-middle-grey;
        }
    }
    .slick-current {
        img {
            border-radius: 10px;
        }
    } 
    .products-imagescover {
        position: relative;
        .slick-dots {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: -20px;
            width: 100%;
            padding: 0 15px;
            li {
                width: 100%;
                button {
                    height: 5px;
                    background: $color-light-grey;
                    font-size: 0;
                    max-width: 140px;
                    min-width: 100%;
                    border-radius: 2px;
                    border: 1px solid $color-light-grey;
                }
                &.slick-active {
                    button {
                        background: $color-dark-blue;
                    }
                }
            }
        }
    }
    .card-block-custom {
        .dotted {
            position: relative;
        }
        .empty-block {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .custom-file {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            opacity: 0;
            &-input {
                height: 100%;
                cursor: pointer;
            }
        }
    }
    .product-accessories {
        @include media-breakpoint-mobile {
            .products {
                flex-wrap: nowrap;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }
                .product-miniature {
                    max-width: 70%;
                    flex: 0 0 70%;
                }
            }
        }
    }
}

.current-price,
.block-price-total {
    .title {
        font-size: 16px;
        .label {
            font-size: 16px;

        }
    }
}
.label-ref {
    font-size: 16px;
}

.product-information {
    ul,ol {
        list-style: initial;
        margin-left: 15px;
    }
    h2 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
    }
}