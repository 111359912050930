// Checkout Step
    // Reset -> _commons.scss
        /*
            .step-title
            .-current .card-header
            line 210 -> deleted .card-body .card-header
            .selected .card
            .card-body_add-address
            .nav-tabs-info
            .delivery-option
            .carrier-price
            .carrier-name
        */
    // Reset -> address-selector-block.tpl
        /*
            line 28 : col-lg-4 (-> col-lg-6)
            line 46 : card-body small (deleted small)
            line 50 : card-footer small (deleted small)
            line 53 : edit-address text-muted (deleted text-muted)
            line 60 : edit-address text-muted (deleted text muted)
        */
    // Reset -> addresses.tpl
        /*
            line 68 : col-12 col-md-6 col-lg-4 mb-3 (deleted col-12 col-md-6 col-lg-4)
            line 68 - 74 : Move
            line 71 : <i> (deleted <i>)
        */

    // Reset -> personnal-information.tpl
        /*
            line 51 : nav-tab--center (deleted nav-tab--center)
            line 70 : add nav-item "|"
        */
    // Modification -> login-form.tpl
        /*
            line 43 : add text-underline class
        */

    // Modification -> checkout-step.tpl
        /*
            line 38 : add img success.svg and comment <i>
        */
    // Modification -> shipping.tpl
        /*
            line 29 : add block <div>
            line 85 : add <p class="title">
            line 92 : modifify row textarea
        */
    // Modification -> payment.tpl
        /*
            line 5 : add block <div>
        */
    // Modification -> cart-summary-totals.tpl
        /*
            line 29 : comment block cart-summary-line and paste this block in cart-summary-subtotals
            line 29 : add block TVA --> {($cart.totals.total_including_tax.amount-$cart.totals.total.amount)|replace:'.':','} €
        */

    // Modification -> cart.scss
        /*
            .product-line-info
        */
    // Modification -> cart-summary-product-line.tpl
        /*
            line 37 : remove text-muted class
        */
    // Modification -> footer.tpl (checkout)
        /*
            line 26 : remove text-center class and add container class
            line 28 : add reassurance.tpl
        */
    // Modification -> header.tpl (checkout)
        /*
            line 31 : add block number and payment block
        */
    // Modification -> header.scss
        /*
            .header-nav--checkout
        */
    // Modification -> address-form.tpl
        /*
            line 33 : add div with d-flex class and form-options-checkout class
            line 36 : add btn-cancel class
        */
    // Structure Checkout Step
        /*
            <section class="checkout-step">
                <div class="card-header">
                    <h1 class="step-title h3"> --> [.step-title => _commons.scss]
                        <span class="step-number">X</span>
                    </h1>
                </div>
            </section>
        */
    //Background Header 
        $step-header-bkgd : #F6F4F3;
    
    //Background Section Checkout Step
        $step-section-bkgd : #F6F4F3;

    //Title Color
        $step-title-color : $color-title;

    //Color Actions Link Address Box
        $step-address-box-actions-link-color : #0B2653;

    //Gap Between Checkout Step
        $step-header-gap : 15px;

    //Size Border Radius Header
        $step-header-radius-size : 10px;

    //Step Address : Address Box
        $step-address-box-border : 1.5px solid #9C9B9B;
        $step-address-box-radius : 10px;
        $step-address-box-bkgd-color : transparent;
        $step-address-box-header-title-font : $font;
        $step-address-box-header-title-size : 18px;
        $step-address-box-header-title-lineheight : 25px;
        $step-address-box-header-title-color : $step-title-color;

        $step-address-box-content-font : $font;
        $step-address-box-content-size : 16px;
        $step-address-box-content-lineheight : 25px;
        $step-address-box-content-weight: 300;





@mixin address-box {
    .card {
        background-color: $step-address-box-bkgd-color;
        border-radius: $step-address-box-radius;
        border: $step-address-box-border;
        &-header {
            background-color: $step-address-box-bkgd-color;
            border-bottom: none;
            .custom-control {
                &-label {
                    font-family: $step-address-box-header-title-font;
                    font-size: $step-address-box-header-title-size !important;
                    line-height: $step-address-box-header-title-lineheight !important;
                    color: $step-title-color !important;
                }
            }
        }
        &-body {
            font-family: $step-address-box-content-font;
            font-size: $step-address-box-content-size;
            font-weight: $step-address-box-content-weight;
            line-height: $step-address-box-content-lineheight; 
            padding-left: 2.75rem;
        }
        &-footer {
            background-color: $step-address-box-bkgd-color;
            border-top: none;
            display: flex;
            justify-content: space-between;
        }
    }
}

@mixin checkout-box {
    .card {
        border-radius: 5px;
        background: $color-lighter-grey;
        border: none;
        &-footer {
            @extend .title;
            background-color: $color-light-grey;
            border-radius: 0px 0px 5px 5px;
            border-top: none;
            color: $color-typo;
        }
    }
}

.checkout-process {
    display: flex;
    flex-direction: column;
    gap: $step-header-gap;
}

.address-item {
    @include address-box;
}

.checkout-step {
    border-radius: 10px;
    border: none;
    background: $color-lighter-grey;
    .card-header {
        border-bottom: none;
        background-color: transparent;
    }
}

#delivery_message::placeholder {
    @extend .legend;
    color: $color-middle-grey;
}

.carrier-delay {
    font-family: $font;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-typo;
}

#delivery {
    .title {
        color: $color-dark-blue;
    }
}

.payment-options {
    .payment-option {
        padding: 30px 27px;
        border: 1px solid $color-middle-grey;
        border-radius: 10px;
        margin-bottom: 1rem;
        .custom-control.custom-radio {
            .custom-control-label {
                @extend .title;
                color: $color-typo;
                padding-left: 24px;
            }
        }
    }
}

#checkout {
    .order-checkout { 
        @include checkout-box;
        .cart-summary-products {
            p:first-child {
               @extend .title;
               color: $color-middle-grey; 
            }
            .link__showsummary {
                font-size: 16px;
                line-height: 25px;
                font-weight: 500;
                color: $color-button;
                &:hover {
                    color: $color-button !important;
                }
            }
            #cart-summary-product-list {
                .media-list {
                    &__item {
                        font-size: 14px;
                        line-height: 17px;
                        .product-name {
                            font-weight: 500;
                            color: $color-typo;
                        }
                        .product-quantity {
                            font-weight: 700;
                        }
                        .product-price {
                            color: $color-info;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .cart-summary-subtotals-container {
            .cart-summary-line {
                color: $color-typo;
                font-size: 18px;
                line-height: 25px;
                &#cart-subtotal-products {
                    @extend .title;
                }
                &.info {
                    @extend .h3;
                    color: $color-info;
                }
            }
        }
    }
}

.edit-address,
.delete-address {
    color: $color-dark-blue;
}

.form-options-checkout {
    gap: 15px;
    .btn-primary {
        &.btn-lg {
            width: 50% !important;
        }
    }
    .btn-cancel {
        width: 50%;
    }
}

#js-checkout-summary {
    &.card {
        border: none;
        .card-block {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .cart-summary-products {
            p:first-child {
                color: $color-middle-grey;
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
                font-family: $font;
            }
            .link__showsummary {
                line-height: 25px;
                font-weight: 500 !important;
                font-size: 16px !important;
                color: $color-button;
            }
        }
        .cart-summary-subtotals-container {
            .cart-summary-line {
                font-size: 18px;
                color: $color-typo;
                line-height: 25px;
            }
            #cart-subtotal-products {
                font-family: $font;
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
            }
        }
        .card-footer {
            padding: 0;
            background: $color-lighter-grey ;
            .cart-total {
                background: $color-light-grey;
                padding: 12px 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}

#delivery-address {
    margin: 0 auto;
    .cancel-address {
        border-radius: 5px;
        color: #ffffff;
        text-transform: uppercase;
        padding: 15px 30px;
    }
}

.page-order-confirmation {
    background: transparent !important;
}