.block-category {
    margin-bottom: 50px;
    .container {
        @include media-breakpoint-mobile {
            padding: 0;
        }
    }
    &-title {
        color: #ffffff;
        display: flex;
        background: $color-dark-blue;
        width: max-content;
        padding: 10px 20px;
        border-radius: 10px;
        margin: 0 auto;
        @include media-breakpoint-mobile {
            width: 100%;
        }
        svg {
            height: 40px;
            width: 40px;
            margin-right: 15px;
        }
        .h2 {
            margin-bottom: 0;
            @include media-breakpoint-mobile {
                font-size: 25px;
                text-align: center;
                justify-content: center;
            }
        }
    }
    &-description {
        margin: 26px auto 0 auto;
        width: 100%;
        
    }
}

.category-sub-menu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-bottom: 80px;
    @include media-breakpoint-mobile {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 15px;
        margin-bottom: 30px;
    }
    .sub-category {
        background: $color-dark-blue;
        padding: 21px 32px;
        border-radius: 10px;
        @include media-breakpoint-mobile {
            width: 90%;
            margin: 0 auto;
        }
        a {
            color: #ffffff;
            line-height: 22px;
        }
        .sub-category-image {
            .category-sub-link {
                font-weight: 700;
                text-decoration: underline;
            }
        }
        .sub-category-content {
            .category-sub-link {
                &:hover {
                    color: $color-button !important;
                    font-weight: 700;
                }
            }
        }
        &-link {
            margin-bottom: 0;
            a {
                &:hover {
                    color: $color-button !important;
                }
            }
        }
        &-img {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 200px;
        }
    }
    &-2 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        margin-bottom: 50px;
        @include media-breakpoint-mobile {
            grid-template-columns: repeat(2, 1fr);
        }
        .sub-category-2 {
            padding: 0;
            border-radius: 10px;
            border: 1px solid $color-dark-blue;
            overflow: hidden;
            .sub-category-link {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 72px;
                text-align: center;
                background: $color-dark-blue;
                margin-bottom: 0;
                a {
                    color: #ffffff;
                    font-weight: 700;
                    &:hover {
                        color: $color-button !important;
                    }
                }
            }
        }
        img {
            width: 100%;
            aspect-ratio: 1/1;
        }
        .cat-bis {
            display: block;
            border: 1px solid $color-button;
            .sub-category-link {
                background: #ffffff;
            }
        }
    }
}

.title-products-cat {
    width: 50%;
    margin: 0 auto;
    color: $color-typo;
    @include media-breakpoint-mobile {
        width: 90%;
        margin: 0 auto 20px auto;
    }
}

.cat-bis {
    border: 2px solid $color-button;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff !important;
    color: $color-button;
    // display: flex;
    a {
        color: $color-button !important;
    }
}

#category {
    .reassurance {
        margin-top: 60px;
    }
    #wrapper {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

#category-description {
    font-size: 14px;
    line-height: 19.5px;
    h2 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
    }
}

.long-description {
    ul {
        list-style: initial;
    }
}