.form-group {
    label {
        font-size: 18px;
        line-height: 25px;
        color: $color-title;
        font-weight: 700;
    }
    .form-control, .custom-select {
        background-color: transparent;
        border: 1px solid $color-middle-grey;
        border-radius: 3px;
        &:focus {
            box-shadow: none;
        }
    }
}
.form-control {
    &:focus {
        box-shadow: none;
        border-color: $color-dark-blue;
    }
}
.form-footer {
    text-align: center;
    button {
        padding: 15px 30px;
        text-transform: uppercase;
    }
}
.custom-control {
    &.custom-checkbox,
    &.custom-radio {
        .custom-control-input{
            &:checked~.custom-control-label::before{
                border-color: $color-typo;
                background-color: $color-typo;
            }
            &:focus:not(:checked)~.custom-control-label::before {
                border-color: $color-typo;
            }
        }
        .custom-control-label {
            font-size: 14px;
            line-height: 25px;
            color: $color-typo;
            font-weight: 500;
            &::before {
                border-radius: 4px;
                box-shadow: none;
                background-color: transparent;
                border: 1.5px solid $color-typo;
            }
        }
    }
    &.custom-radio {
        .custom-control-input {
            &:checked~.custom-control-label::before {
                background-color: transparent;
            }
            &:checked~.custom-control-label::after {
                background-image: url(../img/radio-button.svg);
            }
        }
        .custom-control-label {
            font-weight: 300;
            &::before {
                border-radius: 10px;
            }
        }
    }
}

#checkout {
    .btn-primary {
        &.btn-lg {
            width: 100%;
        }
    }
}

#customer-form {
    width: 80%;
    @include media-breakpoint-mobile {
        width: 100%;
    }
    .form-group {
        &.firstname,
        &.lastname {
            @extend .col-6;
        }
        &.id_gender,
        &.email,
        &.password,
        &.birthday,
        &.siret,
        &.company,
        &.new_password {
            @extend .col-12;
        }
        &.optin,
        &.customer_privacy,
        &.newsletter,
        &.psgdpr {
            padding-left: 15px;
        }
        &.id_gender {
            .label {
                @extend .col-12;
                padding-left: 0;
            }
        }
    }
    p {
        padding-left: 15px;
    }
}

#delivery-address,
#address-customer {
    width: 80%;
    @include media-breakpoint-mobile {
        width: 100%;
    }
    .form-group {
        &.alias,
        &.address1,
        &.address2,
        &.id_country,
        &.company,
        &.vat_number,
        &.phone,
        &.phone_mobile {
            @extend .col-12;
        }
        &.firstname,
        &.lastname,
        &.postcode,
        &.city{
            @extend .col-6;
        }
    }
    .custom-control {
        &.custom-checkbox {
            margin-left: 15px;
        }
    }
}

label {
    &.required {
        &::after {
            content: '*';
        }
    }
}

.panel-title-heading {
    border-bottom: 2px solid $color-light-grey;
    padding-bottom: 34px;
    color: $color-dark-blue;
    margin-bottom: 40px;
    text-align: center;
}

#ets_cft_page {
    .page-content {
        background: transparent;
        padding: 0 !important;
    }
    .ets_cfu_input_submit {
        text-align: center;
    }
    .ets_cfu_input_acceptance {
        display: flex;
        font-size: 16px;
        line-height: 25px;
        font-weight: 300;
    }
    .ets_cfu_input_recaptcha {
        .ets_cfu_form-control {
            background: none;
            border: none;
        }
    }
}