.l-header{
  background-color: $header-bg;
  // margin-bottom: $spacer;
  // box-shadow: $header-box-shadow;
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  padding-bottom: $spacer*1.25;
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
  @include media-breakpoint-mobile {
    padding-right: 24px !important;
    padding-left: 24px !important;
    margin-top: 0;
    margin-bottom: 17px;
  }
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
  //Modification
  .title {
    color: $color-dark-blue;
  }
  &__payment {
    p {
      margin-bottom: 0;
      font-weight: 700;
    }
  }
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}

.header-actions {
  display: flex;
  align-items: flex-end;
  &__phone,
  &__item {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: $color-dark-blue;

    svg {
      width: 16px;
      height: 16px;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.search-widget__input-right {
  border: 2px solid $color-dark-blue;
  border-radius: 2px;
  &::placeholder {
    font-size: 13px;
    line-height: 25px;
    font-weight: 300;
    color: #6F6F6F;
  }
}

.material-icons {
  &.search {
    color: $color-dark-blue;

  }
}

//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}

#header {
  .header-top {
    @include media-breakpoint-mobile {
      flex-wrap: nowrap;
    }
  }
}