.btn-primary {
    background-color: $color-button;
    border-color: $color-button;
    border-radius: 5px;
    &:hover {
        color: $color-button !important;
        background-color: transparent;
        border-color: $color-button;
        svg {
            fill: $color-button;
        }
    }
    &.disabled,
    &:disabled {
        background-color: transparent;
        border-color: $color-middle-grey;
        color: $color-typo;
    }
    &:focus {
        background-color: $color-button;
        box-shadow: none;
        border-color: $color-button;
        color: #ffffff !important;
    }
    &:not(:disabled):not(.disabled):active{
        background-color: $color-button;
        border-color: $color-button;
    }
    svg {
        fill: #ffffff;
    }
}

.btn-cancel {
    background-color: $color-middle-grey;
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 0.5rem 1rem;
}

.btn-light {
    background-color: $color-dark-blue;
    color: #ffffff;
    border: 1px solid $color-dark-blue;
    &:hover {
        color: $color-dark-blue;
        background-color: transparent;
        border: 1px solid $color-dark-blue;
    }
}

.link {
    font-size: 15px;
    font-weight: 400;
    text-decoration: underline;
    color: $color-typo;
}

.btn-rounded {
    border-radius: 5px;
    text-transform: uppercase;
}

.btn-outline {
    &-secondary {
        color: $color-button;
        border-color: $color-button;
        &:hover {
            border-color: $color-button;
            background-color: $color-button;
        }
    }
}

.btn {
    &:focus {
        box-shadow: none;
    }
}

.btn-link {
    color: $color-typo;
}