// Reset style Wapiti


.account-links{
  @extend .align-items-stretch;
  > a{
    margin-bottom: $spacer;
  }
  &-item {
    display: block;
    width: 20%;
  }
  .link-item{
    @extend .card;
    box-shadow:$card-box-shadow;;
    padding: $spacer;
    text-align: center;
    // text-transform: uppercase;
    font-weight: 300; //modification
    height: 100%;
    //modification
    border: none;
    i{
      margin: 0 auto $spacer;
      font-size: $font-size-base*3;

    }
    svg {
      width: 65px;
      height: 65px;
      margin: 0 auto 25px auto;
    }
  }
}

.address{
  @extend .card;
  height: 100%;
  //Modification
  background-color: $color-lighter-grey;
  border-radius: 10px;
  border: none;
  padding: 26px;
}
.address-header{
  @extend .card-header;
  //Modification
  background-color: transparent;
  padding: 0 0 12px 0;
  color: $color-title-box;
  border-bottom: 1.5px solid $color-middle-grey;
  @extend .title;
  a {
    float: right;
    font-weight: 300;
    font-size: 15px;
    display: flex;
    align-items: baseline;
    gap: 10px;
    color: $color-title-box;
    svg {
      width: 18px;
      height: 18px;
      fill: $color-title-box;
    }
  }
}
.address-body{
  @extend .card-body;
  //Modification
  padding-left: 0px;

}
.address-footer{
  @extend .card-footer;
  display: flex;
  justify-content: flex-end;
  //Modification
  background-color: transparent;
  padding: 0;
  border-top: none;
  color: $color-dark-blue;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
}

.addresses-footer {
  text-align: center;
  a {
    width: 50%;
    border-radius: 5px;
    padding: 15px;
    text-transform: uppercase;
  }
}

.form--100{
  min-width: 100%;
  width: 100%;
}

.title-block {
  margin-bottom: 50px;
  &-subtitle {
    font-weight: 700;
    color: $color-typo;
  }
}

.account-link{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0.75rem;
  font-weight: 500;
  font-size: 18px;
  color: $color-dark-blue;
  svg {
    width: 21px;
    height: 15px;
  }
}

