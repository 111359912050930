.menu-sigma {
    color: #ffffff;
    position: absolute;
    z-index: 10;
    padding: 60px 0 20px 0;
    width: 100%;
    display: none;
    top: 170px;
    // height: 70%;
    overflow: hidden;
    .title-category-mobile {
        font-size: 25px;
        font-weight: 600;
        line-height: 25px;
    }
    @include media-breakpoint-mobile {
        z-index: 99999;
        top: 130px;
        padding: 0 0 10px 0;
    }
    a {
        color: #ffffff;
        display: block;
        &:hover {
            color: $color-button !important;
        }
    }
    .active-green {
        border-bottom: 1px solid $color-button;
        @include media-breakpoint-mobile {
            border-bottom: 0px;
        }
        .list-cat-item-link {
            color: $color-button !important;
            @include media-breakpoint-mobile {
                color: #ffffff !important;
            }
        }
        .arrow-right {
            display: block !important;
            @include media-breakpoint-mobile {
                display: none !important;
            }
        }
    }
    .active {
        border-bottom: 1px solid $color-dark-blue;
        .list-cat-item-link {
            color: $color-dark-blue !important;
        }
        .arrow-right {
            display: block !important;
        }
    }
    .main-cat-sigma {
        width: 27%;
        @include media-breakpoint-mobile {
            width: 100%;
            height: 65vh;
            overflow-y: scroll;
        }
        .list-cat {
            &-item {
                margin-bottom: 1em;
                @include media-breakpoint-mobile {
                    margin-bottom: 0;
                    border-bottom: 1px solid #ffffff;
                }
                &-link {
                    color: #ffffff;
                    font-weight: 600;
                    font-size: 17px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 5px;
                    @include media-breakpoint-mobile{
                        font-size: 20px;
                        padding: 20px 0;
                    }
                    &-block {
                        .picto {
                            width: 25px;
                            height: 25px;
                            margin-right: 10px;
                            @include media-breakpoint-mobile{
                                width: 30px;
                                height: 30px;
                                margin-right: 20px;
                            }
                        }
                    }
                    svg {
                        width: 6px;
                        height: 11px;
                    }
                    .arrow-right {
                        display: none;
                    }
                    &:hover{
                        color: $color-button !important;
                        border-bottom: 1px solid $color-button;
                        @include media-breakpoint-mobile {
                            color: #ffffff !important;
                        }
                        .arrow-right {
                            display: block;
                        }
                    }
                }
            }

        }
    }
    .subcategory-sigma {
        width: 73%;
        margin-left: 30px;
        @include media-breakpoint-mobile{
            display: none;
            width: 100%;
            margin-left: 0;
            height: 65vh;
            overflow-y: scroll;
        }
        &-level-2 {
            border-left: 1px solid #ffffff;
            padding-left: 28px;
            height: 100%;
            @include media-breakpoint-mobile {
                border-left: 0;
                padding-left: 0;
            }
            &-wrapper {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-row-gap: 20px;
                grid-column-gap: 20px;
                @include media-breakpoint-mobile {
                    display: block;
                }
                &-title {
                    font-size: 15px;
                    line-height: 25px;
                    text-decoration: underline;
                    font-weight: 700;
                    margin-bottom: 10px;
                    @include media-breakpoint-mobile {
                        border-bottom: 1px solid #ffffff;
                        text-decoration: none;
                        padding-bottom: 20px;
                        font-size: 20px;
                    }
                }
                &-subcategory-link {
                    font-size: 14px;
                    @include media-breakpoint-mobile {
                        font-size: 20px;
                        border-bottom: 1px solid #ffffff;
                        font-weight: 600;
                        line-height: 25px;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
    &-green {
        color: $color-button;
        display: none;
        position: absolute;
        z-index: 10;
        padding: 60px 0 20px 0;
        width: 100%;
        background: #ffffff;
        top: 159px;
        @include media-breakpoint-mobile {
            z-index: 9999;
            background: $color-dark-blue;
            height: 69%;
            top: 130px;
            padding: 0 0 20px 0;
        }
        .title-category-mobile {
            font-size: 25px;
            font-weight: 600;
            line-height: 25px;
            color: #ffffff;
        }
        .main-cat-sigma {
            width: 27%;
            @include media-breakpoint-mobile {
                width: 100%;
                overflow-y: scroll;
                height: 63vh;
            }
            .list-cat {
                &-item {
                    font-weight: 600;
                    margin-bottom: 10px;
                    @include media-breakpoint-mobile {
                        border-bottom: 1px solid #ffffff;
                        margin-bottom: 0;
                    }
                    &-link {
                        color: $color-button;
                        display: flex;
                        justify-content: space-between;
                        @include media-breakpoint-mobile {
                            color: #ffffff;
                            font-size: 20px;
                            padding: 10px 20px;
                        }
                        svg {
                            width: 6px;
                            height: 11px;
                        }
                        .arrow-right {
                            display: none;
                        }
                        &:hover {
                            color: $color-dark-blue;
                            border-bottom: 1px solid $color-dark-blue;
                            .arrow-right {
                                display: block;
                            }
                        }
                        &-block {
                            padding-bottom: 11px;
                        }
                    }
                }
            }
        }
        .subcategory-sigma {
            width: 73%;
            margin-left: 30px;
            @include media-breakpoint-mobile {
                width: 100%;
                margin-left: 0;
            }
            &-level-2 {
                border-left: 1px solid $color-button;
                padding-left: 28px;
                height: 100%;
                @include media-breakpoint-mobile {
                    border-left: 0;
                    padding-left: 0;
                }
                &-wrapper {
                    &-title {
                        font-size: 15px;
                        line-height: 25px;
                        font-weight: 700;
                        margin-bottom: 20px;
                        color: $color-button;
                        display: block;
                        @include media-breakpoint-mobile {
                            color: #ffffff;
                            font-size: 20px;
                            padding: 10px 20px;
                            border-bottom: 1px solid #ffffff;
                            margin-bottom: 0;
                        }
                        &:hover {
                            color: $color-dark-blue;
                        }
                    }

                }
            }
        }
        .active {
            border-bottom: 1px solid $color-dark-blue;
            @include media-breakpoint-mobile {
                border-bottom: 1px solid #ffffff;
            }
            .list-cat-item-link {
                color: $color-dark-blue !important;
                @include media-breakpoint-mobile {
                    color: #ffffff !important;
                }
            }
            .arrow-right {
                display: block !important;
                @include media-breakpoint-mobile {
                    display: none !important;
                }
            }
        }
    }
} 

.mm_menus_li {
    &.sigma-products-categories {
        position: relative;
        a {
            .mm_menu_content_title {
                &::before {
                    content: url(../img/menu.png);
                    @include media-breakpoint-mobile {
                        display: none;
                    }
                    
                }
            }
        }
    }
    &.sigma-univers-categories,
    &.sigma-realisations-categories {
        position: relative;
        a {
            .mm_menu_content_title {
                &::after {
                    content: url(../img/arrow-menu.png);
                    @include media-breakpoint-mobile {
                        display: none;
                    }
                }
            }
        }
    }
}

#header {
    .layout_layout3 {
        .mm_menus_li {
            &:hover > a{
                color: $color-button !important;
            }
            &:hover {
                &.sigma-products-categories {
                    a {
                        .mm_menu_content_title {
                            &::before {
                                content: url(../img/menu-green.png);
                            }
                        }
                    }
                }
                &.sigma-univers-categories,
                &.sigma-realisations-categories {
                    a {
                        .mm_menu_content_title {
                            &::after {
                                content: url(../img/arrow-menu-green.png);
                            }
                        }
                    }
                }
            }
            
        }
    }
}

.sigma-menu-open {
    &::after {
        content: '';
        width: 35px;
        height: 20px;
        background: $color-dark-blue;
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: rotate(45deg);
    }
    a {
        .mm_menu_content_title {
            color: $color-button;
            &::before {
                content: url(../img/menu-green.png) !important;
            }
            @include media-breakpoint-mobile {
                color: #ffffff;
            }
        }
    }
}

.sigma-menu-open-white {
    &::after {
        content: '';
        width: 35px;
        height: 20px;
        background: #ffffff;
        position: absolute;
        bottom: -15px;
        left: 50%;
        transform: rotate(45deg);
    }
    a {
        .mm_menu_content_title {
            color: $color-button;
            &::after {
                content: url(../img/arrow-menu-green.png) !important;
            }
            @include media-breakpoint-mobile {
                color: #ffffff;
            }
        }
        @include media-breakpoint-mobile {
            color: #ffffff;
        }
    }
}

.mm_menus_ul {
    overflow: hidden;
}

.display {
    display: block;
    &-none {
        display: none;

    }
}
.image-cover-category {
    width: 100%;
    @include media-breakpoint-mobile {
        display: none !important;

    }
}
.ybc-menu-toggle {
    width: initial !important;
}

.header-menu-mobile-sigma {
    color: #ffffff;
    position: relative;
    .pull-left {
        position: absolute;
        top: 40%;
        left: 1em;
    }
    .pull-right {
        position: absolute;
        top: 40%;
        right: 1em;
    }
    @include media-breakpoint-desktop {
        display: none;
    }
}

.ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_li.menu-primary span {
    @include media-breakpoint-desktop {
        background: #76B82E;
        box-shadow: 0 2px 19px 0 rgb(0 0 0 / 10%);
        font-weight: 700;
        font-size: 14px;
        border-radius: 10px;
        transition: all .3s ease-out;
        color: #ffffff;
        padding: 5px 10px !important;
        &:hover {
            background: #4b9849;
        }
    }
}

.ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_li.menu-primary {
    @include media-breakpoint-desktop {
        margin-left: 16px;
    }
}
.ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul {
    @include media-breakpoint-desktop {
        display: flex !important;
        align-items: center;
    }
}