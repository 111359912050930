body {
  //Modification
  font-family: $font;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
}
//Modification
hr {
  border-top: 1px solid $color-middle-grey;
}
//Modification
.reassurance {
  margin-bottom: 60px; 
  @include media-breakpoint-mobile {
    margin: 40px 0 60px 0;
  }
  &-item {
    align-items: center;
    gap: 15px;
    @include media-breakpoint-mobile {
      text-align: center;
      &.space {
        margin-top: 36px;
      }
    }
    img {
      width: 80px;
      @include media-breakpoint-mobile {
        width: 55px;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 25px;
      font-weight: 700;
      color: $color-dark-blue;
    }
    span {
      font-size: 14px;
      line-height: 25px;
      color: $color-middle-grey;
      font-weight: 300;
    }
  }
}
//Modification
.text-primary {
  color: $color-typo !important;
}

.icon-add {
  font-weight: 900;
}

a{
  //Modification
  text-decoration: none;
  color: #75B82C;
  &:hover,&.text-primary:hover {
    color: $color-typo !important;
  }
}
sup{
  top:-0.7em;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }

}
.page-wrapper,.card-block{
  @extend .card;
  //Modification
  border: none;
}
.page-header{
  @extend .card-header;
  //Modification
  background-color: transparent;
  border-bottom: none;
  h1{
    margin-bottom: 0;
    //Modification
    text-align: center;
  }
}
.page-content,.card-block{
  @extend .card-body;
  background: $color-lighter-grey;
  border-radius: 10px;
}
//Modification
#addresses,
#history,
#order-slip {
  .page-content,.card-block {
    @extend .card-body;
    background-color: transparent;
  }
}

.card-block{
  box-shadow:$card-box-shadow;;
}

.card-block-custom {
  background: $color-lighter-grey;
  border-radius: 10px;
  padding: 10px;
  .dotted {
    border: 1.5px dashed;
    border-radius: 7px;
    padding: 27px;
    &-green {
      border-color: #76B82E;
      border-width: 3px;
    }
    &-red {
      border-color: #FA4026;
    }
    svg {
      width: 43px;
      height: 43px;
    }
  }
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;

}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  //Modification
  // box-shadow: $bs-touchspin-boxshadow;
  border: 1.5px solid $color-light-grey;
  border-radius: 5px;
  > input {
    max-width: 100px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
    }
  }
  .form-control {
    background-color: transparent;
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    //Modification
    background-color: transparent;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  // Modification
  border-radius: 10px;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
  //MODIFICATION
  color: $color-title;
  .step-number {
    margin-right: 25px;
  }
  //
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
// .-current .card-header{
//   background-color: $white;
// }


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }

  .selected .card{
    // border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    //Modification
    font-size: 14px;
    line-height: 25px;
    .active{
      //Modification
      font-weight: 700;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    //Modification
    padding-left: 10px;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  //Modification
  background-color: transparent;
  border: 1px solid $color-middle-grey;
  border-radius: 5px;
  .row {
    align-items: baseline;
  }
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  //Modification
  font-weight: 700;
  font-family: $font;
  font-size: 14px;
  line-height: 20px;
  color: $color-typo;
}
  .carrier-price{
    //Modification
    color: $color-info;
    @extend .title;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$color-middle-grey;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  //Modification
  justify-content: initial !important;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  gap: 12px;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
    padding: 0;
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: $color-typo;
    }
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    //Modification
    // width: 75%;
    // max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

.psgdprgetdatabtn17 {
  float: none !important;
}

.psgdprinfo17 {
  background: none !important;
}

.table-collapse {
  border-collapse: separate;
  border-spacing: 0 10px;
  th, td {
    border-top: none;
  }
  thead {
    background: $color-lighter-grey;
    tr {
      th {
        border-bottom: none;
        vertical-align: middle;
        &:first-child{
          border-radius: 10px 0 0 10px;
        }
        &:last-child{
          border-radius: 0 10px 10px 0;
        }
      }
    }
    &::after{
      content: '.';
      color: #ffffff;
      background: #ffffff;
      display: block;
      line-height: 5px;
    }
  }
  tbody {
    tr {
      background: $color-lighter-grey;
      th {
        vertical-align: middle;
        color: $color-title-box;
        font-weight: 700;
        &:first-child{
          border-radius: 10px 0 0 10px;
        }
      }
      td {
        vertical-align: middle;
        &:last-child{
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }
}

.breadcrumb {
  background-color: transparent;
  font-size: 14px;
  color: $color-typo;
  &-item {
    &.active {
      font-weight: 500;
      color: $color-typo;
    }
    &+.breadcrumb-item {
      &::before {
        content: ">";
      }
    }
  }
}

.product-item-field-info {
  font-size: 14px;
  font-weight: 500;
  color: $color-middle-grey;
  &_blue {
    @extend .product-item-field-info;
    color: $color-dark-blue;
  }
}

.reference {
  color: $color-middle-grey;
  font-weight: 700;
}

.attribute {
  color: $color-dark-blue;
  font-weight: 700; 
}

.gap {
  &-10 {
    gap: 10px;
  }
  &-30 {
    gap: 30px;
  }
  &-20 {
    gap: 20px;
  }
}

.box {
  background: $color-lighter-grey;
  border-radius: 5px;
  padding: 38px 68px;
  p {
    margin-bottom: 0;
  }
  svg {
    width: 28px;
    height: 28px;
  }
  .crop-product {
    svg {
      width: 108px;
      height: 108px;
    }
  }
  @include media-breakpoint-mobile {
    padding: 15px 18px;
    .attachment {
      width: 100%;
      a {
        width: 100%;
      }
    }
  }
  .partial {
    width: 50%;
    .title {
      color: $color-dark-blue;
    }
    @include media-breakpoint-mobile {
      width: 100%;
      margin-bottom: 24px;
    }
  }
}

.color {
  &-typo {
    color: $color-typo;
  }
}

.product-total-price {
  font-weight: 800;
  font-size: 30px;
  line-height: 23px;
  color: $color-info;
}

.product-miniature {
  font-family: $font;
  .card-product {
    border-radius: 10px;
    padding: 5px 5px 10px 5px;
    background: $color-lighter-grey;
    .card-body {
      padding: 10px 2px 0 2px;
      .product-title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 9px;
        a {
          color: $color-dark-blue;
        }
      }
      .ref {
        font-weight: 300;
        font-size: 13px;
        line-height: 10px;
        color: $color-dark-blue;
        margin-bottom: 5px;
      }
      .product-price-and-shipping {
        .info {
          font-size: 13px;
          color: $color-info;
          line-height: 23px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        .price {
          font-size: 27px;
          line-height: 23px;
          font-weight: 600;
          color: $color-info;
        }
        .regular-price {
          margin-bottom: 5px;
        }
      }
    }
  }
  .add-to-cart {
    text-transform: uppercase;
    margin-top: 10px;
  }
}
.hidden {
  display: none;
}
.bkgd{
  &-grey {
    background: $color-lighter-grey;
  }
  &-blue {
    background: $color-dark-blue;
  }
}
.top-banner {
  p {
    margin-bottom: 0;
    span {
      font-size: 14px;
      line-height: 25px;
      font-weight: 600;
    }
  }
}

.subcat-label {
  background: $color-dark-blue;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 73px;
  border-radius: 6px;
  padding: 0 30px;
  @include media-breakpoint-mobile {
    padding: 0 15px;
  }
  span {
    display: block;
    font-size: 15px;
    line-height: 20px;
    @include media-breakpoint-mobile {
      font-size: 12px;
    }
  }
  svg {
    width: 45px;
    height: 45px;
    margin-right: 30px;
    @include media-breakpoint-mobile{
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    background: $color-button;
    color: #ffffff !important;
  }
}

.subcategories {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  @include media-breakpoint-mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 14px;
    grid-row-gap: 25px;
  }
}
// #index {
// }
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
      margin: 0 6px;
      button {
          font-size: 0;
          height: 9px;
          border-radius: 4.5px;
          padding: 0;
          width: 9px;
          border: none;
      }
      &.slick-active {
          button {
              background: $color-button;
              outline: none;
          }
      }
  }
}

.long-description {
  background: $color-lighter-grey;
  padding: 69px 0;
  @include media-breakpoint-mobile {
    padding: 25px 0;
  }
  &-intro {
    margin-bottom: 40px;
    a {
      margin-top: 25px;
    }
    svg {
      width: 14px;
      height: 12px;
    }
  }
  &-list {
    .category-sub-menu-2 {
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1.5px solid $color-middle-grey;
      padding-bottom: 36px;
      @include media-breakpoint-mobile {
        grid-template-columns: repeat(1, 1fr);
        
      }
      .sub-category-2 {
        img {
          display: none;
        }
        .sub-category-link {
          height: 45px;
          background: transparent;
          a {
            font-size: 14px;
            line-height: 25px;
            color: $color-dark-blue;
          }
        }
      }
    }
  }
  &-content {
    column-count: 2;
    ul {
      padding-left: 1em;
    }
    @include media-breakpoint-mobile {
      column-count: 1;
    }
    h2,h3 {
      color: $color-dark-blue;
    }
  }
}

.h2contenu{
  color: #0B2653;
}

span {
  &[data-link],
  &[data-link-blank] {
    cursor: pointer;
  }
}
div {
  &.link-finder {
    cursor: pointer;
  }
}

#cms {
  .page-wrapper--cms {
    ul {
      list-style: inherit !important;
      padding-left: 1em !important;
    }
  }
}

.page-item.active .page-link {
  background-color: #0B2653;
  border-color: #0B2653;
}

.page-link {
  color: #0B2653;
  border: 1px solid #0B2653;
}

.product-flag {
  background: #ffffff !important;
  color: $color-button !important;
  border-radius: 3px;
  font-size: 11px;
  line-height: 10px;
  border: 1px solid $color-button;
  text-transform: inherit !important;
  margin-left: 0.5rem;
  &.discount-product {
    background: #FA4026 !important;
    color: #ffffff !important;
    border: 1px solid #FA4026 !important;
  }
}

.card-product:hover .product-title{
  color: $color-dark-blue !important;
}

.products {
  .product-miniature {
    @include media-breakpoint-mobile {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

#checkout {
  .header-nav--checkout__payment {
    @include media-breakpoint-mobile {
      display: none;
    }
  }
  .header-nav--checkout {
    .title {
      @include media-breakpoint-mobile {
        font-size: 13px;
        img {
          width: 20px;
        }
      }
    }
  }
}

.custom-link-sigma {
  font-size: 18px;
  line-height: 25px;
  color: #373737;
  font-weight: 500;
  text-decoration: underline;
  margin: 20px 0;
  display: block;
}