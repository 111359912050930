.ets_cfu_span {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: $color-dark-blue;
}

.ets_cfu_form-control {
    border-radius: 3px;
    background: $color-lighter-grey;
    border: 1px solid $color-middle-grey;
}

.ets_cfu_input_acceptance {
    .ets_cfu_help_block {
        font-size: 16px;
        line-height: 25px;
        font-weight: 300;
        color: #000000;
        font-style: normal;
    }
    .ets_cfu_form-control {
        background: transparent;
        border: none;
    }

}

.ets_cfu_input_radio {
    .ets_cfu_form-control {
        border: none;
        background: transparent;
        border-radius: 0;
    }
}

.form-info-sigma {
    border-top: 2px solid $color-light-grey;
    padding-top: 60px;
    .h3 {
       margin-bottom: 59px; 
    }
    svg {
        height: 45px;
        width: 37px;
        margin-bottom: 20px;
    }
    &-content {
        p {
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            font-weight: 700;
            color: $color-dark-blue;
            span {
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
}

.error-form {
    border: 1px solid $color-info;
}

.header-form-contact {
    background: $color-lighter-grey;
    padding-bottom: 35px;
    margin-bottom: 38px;
    p {
        color: $color-typo;
        font-weight: 300;
    }
    .h2 {
        color: $color-dark-blue;
        font-weight: 900;
    }
}

#ets_cft_page {
    #wrapper {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}